import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroBanner = ({ caption, image }) => { 
  return (
    <div className='hero--banner bg-black flex'>
      {(image.format === 'mp4' ?
        <video loop playsInline muted autoPlay className='bg-image  br-08 bg-video' allowFullScreen src={image.video.mp4Url} />:
        <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt={caption} />
      )}
    </div>
  )
}

export default HeroBanner
